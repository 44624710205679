import * as React from 'react'
import Layout from '../components/Layout'

const Thanks = () => {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', 'Lead', { currency: 'USD', value: 9.99 })
    }
  }
  return (
    <>
      <Layout>
        <div>
          <div className="page success">
            <div className="container">
              <div className="message modal">
                <p className="thx-title">Дякуємо!</p>
                <p>Ми зв’яжемося з Вами найближчим часом.</p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Thanks
